import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, DatePicker, Input, Select, Switch } from "antd";
import style from "./checkout.module.scss";
import { detectCardType, removeURLParam } from "./utills";
import { uuidv4 } from "../Thankyou/Utills";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import PaypalPayment from "../subscription/SubsPayPalPayment";
import Alreadypurchased from "../AlreadyPurchased/alreadypurchased";
import DeviceFingerprint from "./FingerPrint";
import CyberSource from "./CyberSource";
import { checkoutSettingsDefaultData, callrzpSubscription } from "./utills";
import { getWordsFromDuration } from "./utills";
import { antdMessageError } from "./utills";
import API from "../../Api/api";
import { Benifits } from "./Benifits";
import Loader from "../loader";
import { ReactComponent as DropDownArrow } from "../../assets/dropdown_arrow.svg";
import { ReactComponent as SelectArrowIcon } from "../../assets/select_arrow_icon.svg";
import { ReactComponent as CalenderIcon } from "../../assets/DateRange_Icon.svg";
import { ReactComponent as AddPlusIcon } from "../../assets/Add_plus_icon.svg";
import Mobile_icon from "../../assets/mobile_icon.svg";
import Search_icon from "../../assets/search_icon.svg";
import AmericanExpressIcon from "../../assets/American_icon.svg";
import VisaCard from "../../assets/Visa_icon.svg";
import MasterCard from "../../assets/masterCard_icon.svg";
import DinerClubCard from "../../assets/Diners_icon.svg";
import DiscoverCard from "../../assets/discover.svg";
import JcbCard from "../../assets/jcb_icon.svg";
import { Country_flag_json } from "../../consts/country-flag-json";
import dropDownStyle from "../../UI/DropDown/commonAntdSelectedBox.module.scss";
import CustomDropDownForSelect from "../../UI/DropDown/CustomDropDown";
import { AddCardDetails } from "./AddCardDetails";
import { AddAddress } from "./AddAddress";
import { SavedAddress } from "./SaveAddress";
import { ApplyCoupon } from "./ApplyCoupon";
import StepUpForm from "./StepUpForm";
import SubsStripePayment from "../subscription/SubsStripePayment";
import { Footer } from "./Footer";
import dayjs from "dayjs";
import { MyContext } from "../../App";
import { getCurrencySymbol } from "../currencies";
import { url } from "inspector";

const Checkout = () => {
  const [contantDetails, setContantDetails] = useState(true);
  const [showStepUp, setShowStepUp] = useState(false);
  const [stepUpUrl, setStepUrl] = useState("");
  const [userDetails, setUserDetails] = useState({
    address: {},
    billingAddress: [],
    name: "",
    phoneNumber: "",
    secondaryEmail: "",
    secondaryPhone: "",
    shippingAddress: [],
    // email: "",
    dateOfBirth: "",
    gender: "",
    countryCode: "",
    isEmailVerified: false,
    isPhoneVerified: false,
  } as any);
  const [subsDetailsData, setSubsDetailsData] = useState({
    benefits: "",
    convertedPrice: 0,
    currency: "",
    duration: 0,
    name: "",
    originalPrice: 0,
    proRataDiscount: 0,
    gift: {
      giftEnabled: false,
      gifting: "OPTIONAL",
    },
    adFree: false,
    digital: false,
    physical: false,
    billingCycleType: "RECURRING",
    couponDiscountPrice: 0,
    epaper: false,
    durationType: "",
    // discounts: [{ amount: 0 }],
  });
  const [checkoutSetting, setCheckoutSetting] = useState(
    checkoutSettingsDefaultData
  );
  const [ssoRedirectUrl, setSSOredirectUrl] = useState("");
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [orderSummary, setOrderSummary] = useState(true);
  const [showAddress, setShowAddress] = useState(false);
  const [cyberSourcedata, setCyberSourcedata] = useState({
    accessToken: "",
    deviceDataCollectionUrl: "",
    refrenceId: "",
  });
  const [cyberSourceSessionId, setCyberSourceSessionId] = useState("");
  const [randomUUid,setRandomUUid]=useState(uuidv4())
  const [subscriptionContentDetails, setsubscriptionContentDetails] = useState(
    {}
  );
  const [cardToken, setCardToken] = useState("");
  const [tax, settax] = useState(0);
  const [renewAmount, setRenewAmount] = useState(0);
  const [inclusiveTax, setInclusiveTax] = useState(0);
  const [isPurchased, setisPurchased] = useState(false);
  // const [month, setMonth] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [redirects, setRedirects] = useState({ privPolicy: "", tnc: "" });
  // const [year, setYear] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const showgift = searchParams.get("showgift")||undefined;
  const sendviewEvent = (data: any) => {
    console.log("ljfhuierhior");
    const SRPviewObject = {
      eventType: "VIEW",
      eventLocation: "SRP",
      subId: searchParams.get("subsId"),
      tierId: searchParams.get("tierId"),
      amount: data.convertedPrice,
      currency: data.currency,
      clientId: searchParams.get("clientId"),
      contentId: searchParams.get("clientContentId"),
      anonId: searchParams.get("anonId"),
      paywallId: localStorage.getItem("paywallId"),
    };
    navigator.sendBeacon(
      `${process.env.REACT_APP_KAFKA_URL}collect/event`,
      JSON.stringify({
        messageBody: [{ ...SRPviewObject }],
      })
    );
  };

  const couponCode = searchParams.get("couponCode") || "";
  const [showRenewline, setshowRenewline] = useState(false);

  const [showRenew, setShowRenew] = useState(true);
  const [isPaypal, setisPaypal] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [internelTYransactionId, setInternelTransactionId] = useState("");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [renew, setRenew] = useState(false);
  const [isGift, setIsGift] = useState(false);
  const [isGiftMandatory, setIsGiftMandatory] = useState(false);
  const [recipientEmail, setrecipientEmail] = useState("");
  const [recipientName, setrecipientName] = useState("");
  const [recieverMessage, setRecieverMessage] = useState("");
  const [sender, setsender] = useState("");
  const [stepUpToken, setStepUpToken] = useState("");
  const [isStripe, setisStripe] = useState(false);
  useEffect(() => {
    window.addEventListener("message", (message) => {
      const secondStep =
        new URLSearchParams(window.location.search).get("secondStep") || "";
      const thirdstep =
        new URLSearchParams(window.location.search).get("thirdStep") ||
        undefined;
      const popUpId =
      new URLSearchParams(window.location.search).get("popUpId") || undefined;
      const secondaryDetails =
      new URLSearchParams(window.location.search).get("secondaryDetails") ||
      undefined;
      console.log(message,'message')
      if(message.data.source==='Transaction-Complete'){
        setShowStepUp(false)
        const url= new URL(`${window.location.origin}/thankyou?transactionId=${transactionId}&clientContentId=${searchParams.get('clientContentId')}&clientId=${searchParams.get("clientId")}&secondStep=${secondStep}&thirdstep=${thirdstep}&popUpId=${popUpId}&secondaryDetails=${secondaryDetails}`)
        window.location.href=url.toString()
      }
      if(message.data.source==='Transaction-Failed'){
window.location.reload()
      }
    });
  }, []);
  useEffect(() => {
    if (stepUpToken && stepUpToken.length > 10) {
      setShowStepUp(true);
      setLoading(true)
    }
  }, [stepUpToken]);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState({
    name: "",
    state: "",
    country: "",
    pincode: "",
    area: "",
  });
  const [billingAddress, setBillingAddress] = useState({
    name: "",
    state: "",
    country: "",
    pincode: "",
    area: "",
  });
  const [cardDetails, setCardDetails] = useState([] as any);
  const [routing, setRouting] = useState({
    color: "",
    gateway: "",
    logoUrl: "",
    publishable_key: "",
    clientName: "",
    merchantId:'poiuytyuiop',
  });
  const handleCardType = (type: string) => {
    if (type === "VS") {
      return VisaCard;
    } else if (type === "MS") {
      return MasterCard;
    } else if (type === "AMEX") {
      return AmericanExpressIcon;
    } else if (type === "DS") {
      return DiscoverCard;
    } else if (type === "DINER") {
      return DinerClubCard;
    } else if (type === "JCB") {
      return JcbCard;
    }
  };
  const [billingSameAsShipping, setbillingSameAsShipping] = useState(false);
  const [indexOfShipingAddress, setIndexOfShipingAddress] = useState(0);
  useEffect(() => {
    if (
      Array.isArray(userDetails?.shippingAddress) &&
      userDetails?.shippingAddress[indexOfShipingAddress]
    ) {
      setAddress({ ...userDetails.shippingAddress[indexOfShipingAddress] });
    }
  }, [indexOfShipingAddress, userDetails?.shippingAddress]);
  useEffect(() => {
    if (checkoutSetting.deliveryAddress.default && subsDetailsData.physical) {
      setShowAddress(true);
    } else {
      // alert('one')
      if (
        (checkoutSetting.deliveryAddress.customization.adsFreeSubscription
          .isRequired &&
          subsDetailsData.adFree) ||
        (checkoutSetting.deliveryAddress.customization.digitalSubscription
          .isRequired &&
          subsDetailsData.digital) ||
        (checkoutSetting.deliveryAddress.customization.physicalSubscription
          .isRequired &&
          subsDetailsData.physical)
      ) {
        // alert('two')

        setShowAddress(true);
      }
    }

    // )
  }, [checkoutSetting, subsDetailsData]);
  useEffect(() => {
    if (
      typeof userDetails?.shippingAddress?.[0] === "object" &&
      userDetails?.shippingAddress?.[0] !== null
    ) {
      setAddress({ ...userDetails?.shippingAddress?.[0] });
    }
  }, [userDetails]);
  const [isModalOpen, setIsModalOpen] = useState({
    addressModal: false,
    cardDetailsModal: false,
    savedAddressModal: false,
    applyCouponModal: false,
    benifits: false,
  });
  const orderForZeroAmount = async () => {
    const giftingDetails = isGift
      ? {
          recieverName: recipientName,
          recieverEmailPhone: recipientEmail,
          recieverMessage: recieverMessage,
          sender: sender,
        }
      : "";
      if(isGift){
        setLoading(false)
       return antdMessageError('You can not Gift a subscription with 100% discount')
      }
    await API.payment.pgIntegrationOnetimeForAll({
      clientId: searchParams.get("clientId") || "",
      subscriptionId:
        searchParams.get("subsId") || searchParams.get("subId") || "",
      anonId: searchParams.get("anonId") || "",
      tierId: searchParams.get("tierId") || "",
      type: "SUBSCRIPTION",
      clientContentId: searchParams.get("clientContentId") || "",
      currency: searchParams.get("currency") ? subsDetailsData.currency : "",
      couponCode: couponCode,
      manuallyRenewed: renew,
      clientReferenceId: searchParams.get("clientReferenceId") || "",
    });
    window.location.href = `${
      window.location.origin
    }/thankyou?clientContentId=${searchParams.get(
      "clientContentId"
    )}&clientId=${searchParams.get("clientId")}`;
  };
  const callRoutingConfig = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const clientId = searchParams.get("clientId") || "";
    const routingResponse = await API.payment.CallRouting({
      clientId: clientId,
      // accessToken: accessToken.accessToken,
    });

    if (!API.validators.checkSuccessCode(routingResponse))
      return console.error("Unable To fetch data");
    localStorage.setItem("logoUrl", routingResponse.data.logoUrl);
    const searchParams1 = new URLSearchParams(window.location.search);
    const paramGateway = searchParams1.get("paymentGateway");
    //hardcoding to check for cybersource
    if (
      paramGateway?.toUpperCase() === "STRIPE" ||
      paramGateway?.toUpperCase() === "RAZORPAY" ||
      paramGateway?.toUpperCase() === "PAYPAL" ||
      paramGateway?.toUpperCase() === "CYBERSOURCE"
    ) {
      setRouting({
        ...routingResponse?.data,
        gateway: paramGateway?.toUpperCase(),
      });
    } else {
      setRouting({ ...routingResponse?.data });
    }
    return routingResponse?.data;
  };

  const [inputValue, SetInputValue] = useState("");

  const GenderArray = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "OTHERS", label: "Others" },
  ];
  const filteredOptions = Country_flag_json.filter(
    (option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.dail_code.toLowerCase().includes(inputValue.toLowerCase())
  );
  const getCyberSourcedata = async () => {
    const result = await checkForUserdata();
    if (result === "STOP") {
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const clientId = searchParams.get("clientId") || "";
    const cardData = expiryDate.split("/");
    const cardExpiryMonth = cardData[0];
    let cardExpiryYear='';
    if(cardData[1]!=undefined){
       cardExpiryYear = `20${cardData[1]}`;
    }else{
      cardExpiryYear=''
    }
   
    // const cardNumber= ;
    // let securityCode = "";

    setbuttonLoading(true);
    if (subsDetailsData.convertedPrice === 0 && renew === false) {
      setLoading(true);
      await orderForZeroAmount();
      return;
    }
    try {
      const data = await API.user.CyberSourceSetUp({
        clientId,
        cardExpiryMonth,
        cardExpiryYear,
        cardNumber: cardNumber.replaceAll(/\s/g, ""),
        securityCode,
        cardTokenId: cardToken,
        cardType: cardType,
        nameOnCard: cardHolderName,
      });
      setCyberSourcedata({
        accessToken: data.data.data.accessToken,
        deviceDataCollectionUrl: data.data.data.deviceDataCollectionUrl,
        refrenceId: data.data.data.referenceId,
      });
    } catch (error) {
      setbuttonLoading(false);
      antdMessageError("Enter card details to proceed with payment.");
    }

    // CyberSource({accessToken:data.data.accessToken,deviceDataCollectionUrl:data.data.deviceDataCollectionUrl,refrenceId:data.data.referenceId})
  };

  const callStripe = async () => {
    const result = await checkForUserdata();
    if (result === "STOP") {
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    let succesfrontendurltemp = `${window.location.origin}/thankyou?`;
    if (subsDetailsData.convertedPrice === 0 && renew === false) {
      setLoading(true);

      await orderForZeroAmount();
      return;
    }
    const numberOfLicense = Number(searchParams.get("numberOfLicense"));
    const giftingDetails = isGift
    ? {
        recieverName: recipientName,
        recieverEmailPhone: recipientEmail,
        recieverMessage: recieverMessage,
        sender: sender,
      }
    : "";
    // userDetails;
  

    const sub = {
      brandlogoUrl: routing.logoUrl,
      successFrontendUrl: succesfrontendurltemp,
      failureFrontendUrl: `${window.location.href}`,
      subscriptionId: searchParams.get("subsId") || searchParams.get("subId"),
      tierId: searchParams.get("tierId"),
      amount: searchParams.get("amount")
        ? +subsDetailsData.convertedPrice + tax
        : "",
      currency: searchParams.get("currency") ? subsDetailsData.currency : "",
      type: "SUBSCRIPTION",
      anonId: searchParams.get("anonId"),
      userDetails: {
        ...userDetails,
        ...(isGift && { isGift: isGift }),
        ...(giftingDetails && { giftingDetails }),
      },
      pageType: searchParams.get("pageType"),
      clientId: searchParams.get("clientId"),
      clientContentId: searchParams.get("clientContentId"),
      renewSubscription: renew,
      // viewId: subscriptionPageParams?.viewId,
      userId: userDetails._id,
      eventLocation: "SRP",
      paywallId:
        searchParams.get("paywallId") === "undefined" ||
        searchParams.get("paywallId") === "null"
          ? null
          : searchParams.get("paywallId"),
      paywallType: searchParams.get("paywallType"),
      userToken: localStorage.getItem("refreshToken"),
      // couponCode: subscriptionPageParams.couponCode,
      // numberOfLicense: subscriptionPageParams?.numberOfLicense,
      journeyId:
        searchParams.get("journeyId") === "undefined"
          ? null
          : searchParams.get("journeyId"),
      segmentId:
        searchParams.get("segmentId") === "undefined"
          ? null
          : searchParams.get("segmentId"),
      couponCode: couponCode,
      clientReferenceId: searchParams.get("clientReferenceId") || "",
      numberOfLicense: Number(numberOfLicense),
    };
    setsubscriptionContentDetails({ ...sub });
  };
  useEffect(() => {
    //@ts-ignore
    if (subscriptionContentDetails?.type === "SUBSCRIPTION") {
      console.log(subscriptionContentDetails, "fduhioujo");
      if (routing.gateway === "STRIPE") setisStripe(true);
      if (routing.gateway === "PAYPAL") setisPaypal(true);
    }
  }, [subscriptionContentDetails]);
  const checkForUserdata = async () => {
    if (
      (checkoutSetting.deliveryAddress.customization.adsFreeSubscription
        .mandatory &&
        subsDetailsData.adFree) ||
      (checkoutSetting.deliveryAddress.customization.digitalSubscription
        .mandatory &&
        subsDetailsData.digital) ||
      (checkoutSetting.deliveryAddress.customization.physicalSubscription
        .mandatory &&
        subsDetailsData.physical)
    ) {
      if (
        !address.area ||
        !address.country ||
        !address.name ||
        !address.pincode ||
        !address.state
      ) {
        antdMessageError("Please provide full delivery address");
        return "STOP";
      }
    }
    if (checkoutSetting.contactInfo.email.mandatory) {
      if (!(userDetails.email || userDetails.secondaryEmail)) {
        antdMessageError("Please provide an Email");
        return "STOP";
      }
    }
    if (checkoutSetting.contactInfo.mobile.mandatory) {
      if (!(userDetails.phoneNumber || userDetails.secondaryPhone)) {
        antdMessageError("Please provide an Phone Number");
        return "STOP";
      }
    }
    if (checkoutSetting.contactInfo.gender.mandatory) {
      if (!userDetails.gender) {
        antdMessageError("Please provide Your gender");
        return "STOP";
      }
    }
    if (checkoutSetting.contactInfo.DOB.mandatory) {
      if (!userDetails.dateOfBirth) {
        antdMessageError("Please provide Date of Birth");
        return "STOP";
      }
    }
 if(isGift){
  if(checkoutSetting.giftingDetails.recipientEmailPhone.mandatory){
    if(recipientEmail.length<5){
      antdMessageError("Please provide recipient Email");
      return 'STOP'
    }
    
  }
  if(checkoutSetting.giftingDetails.message.mandatory){
    if(recieverMessage.length<5){
      antdMessageError("Please provide recipient Message");
      return 'STOP'

    }
    
  }
  if(checkoutSetting.giftingDetails.recipientName.mandatory){
    if(recipientName.length<5){
      antdMessageError("Please provide recipient Name");
      return 'STOP'

    }
    
  }
 }
  
    // return
    if (checkoutSetting.contactInfo.nameInput.mandatory) {
      if (!userDetails.name) {
        antdMessageError("Please provide name");
        return "STOP";
      }
    }
    if (checkoutSetting.billingAddress) {
      if (!billingAddress.area) {
        antdMessageError("Please provide billing address area");
        return "STOP";
      }
      if (!billingAddress.country) {
        antdMessageError("Please provide billing address Country");
        return "STOP";
      }
      if (!billingAddress.pincode) {
        antdMessageError("Please provide billing address pincode");
        return "STOP";
      }
      if (!billingAddress.state) {
        antdMessageError("Please provide billing address state");
        return "STOP";
      }
    }
    let accessToken = localStorage.getItem("accessToken");

    if (userLoggedIn) {
      await API.payment.PutUserData({
        userdata: {
          ...userDetails,
          ...(userDetails.email === "" && { email: undefined }), // Omit email if it is an empty string
          ...(userDetails.gender === "" && { gender: undefined }), // Omit email if it is an empty string
          ...(userDetails.name === "" && { name: undefined }), // Omit email if it is an empty string

          shippingAddress: address.area
            ? [...(userDetails?.shippingAddress || []), address]
            : [...(userDetails?.shippingAddress || [])], // Ensure it's always an array
          billingAddress: billingAddress.area
            ? [...(userDetails?.billingAddress || []), billingAddress]
            : [...(userDetails?.billingAddress || [])],
        },
        accessToken: "",
      });
    }
  };
  const CreateOrderRazorPay = async () => {
    const result = await checkForUserdata();
    if (result === "STOP") {
      return;
    }
    let accessToken = localStorage.getItem("accessToken");

    //remove it for razorpay
    let userId = "";
    // alert('dfljkhisod')
    const searchParams = new URLSearchParams(window.location.search);
    // let accessToken = localStorage.getItem("accessToken") || "";
    if (accessToken != undefined && accessToken.length > 18) {
      const arrayToken = accessToken.split(".");
      const tokenPayload = JSON.parse(atob(arrayToken[1]));
      userId = tokenPayload.sub;
    }
    // alert(renew)
    if (subsDetailsData.convertedPrice === 0 && renew === false) {
      setLoading(true);

      await orderForZeroAmount();
      return;
    }
    // localStorage.setItem('userId',tokenPayload.sub)
    const secondStep =
      new URLSearchParams(window.location.search).get("secondStep") ||
      undefined;
    const popUpId =
      new URLSearchParams(window.location.search).get("popUpId") || undefined;
    const thirdstep =
      new URLSearchParams(window.location.search).get("thirdStep") || undefined;
    // alert(secondStep);
    // alert(thirdstep)
    // return
    const cardData = expiryDate.split("/");
    const month = cardData[0];
    let year='';
    if(cardData[1]!=undefined){
      year = `20${cardData[1]}`;
    }else{
      year=''
    }
    // const year = `20${cardData[1]}`;
    const paywallId = searchParams.get("paywallId");
    const paywallType = searchParams.get("paywallType");
    const contentId = searchParams.get("clientContentId");
    const orderObj = {
      clientId: searchParams.get("clientId"),
      SubscriptionId: searchParams.get("subsId") || searchParams.get("subId"),
      tierId: searchParams.get("tierId"),
      type: "SUBSCRIPTION",
      anonId: searchParams.get("anonId"),
      numberOfLicense: searchParams.get("numberOfLicense"),
      userId: userId,
      secondStep,
      popUpId,
      thirdstep,
      isGift,
      sender,
      recieverMessage,
      paywallId,
      contentId,
      paywallType,
      recipientEmail,
      recipientName,
      userDetails: {
        name: userDetails.name,
        email: userDetails.email,
        phone: userDetails.phoneNumber,
        shippingAddress: address,
        secondaryPhone: userDetails.secondaryPhone,
        secondaryEmail: userDetails.secondaryEmail,
        countryCode: userDetails.countryCode,
        billingAddress: billingAddress,
      },
      cardType,
      renew,
      cyberSourceSessionId,
      routing,
      cardToken,
      cardNumber: cardNumber.replaceAll(/\s/g, ""),
      cardHolderName,
      month,
      year,
      tax,
      subsDetailsData,
      setLoading,
      couponCode,
      clientContentId: searchParams.get("clientContentId") || "",
    };
    setLoading(true);
    callrzpSubscription(orderObj);
    return;
  };
  const redirectToLogin = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const clientId = searchParams.get("clientId") || "";
    const url = new URL(ssoRedirectUrl);
    // y.searchParams.set('clientId',clientId)
    url.searchParams.append("redirectUrl", window.location.href);
    url.searchParams.append("clientId", clientId);
    window.location.href = url.toString();
  };
  const disableFutureAndToday = (current: any) => {
    // Get today's date
    const today = new Date();
    // Set the current date to the end of yesterday
    today.setHours(23, 59, 59, 999);
    return current && current > today;
  };
  const cyberSourceCheckout = () => {
    // localStorage.setItem('userId',tokenPayload.sub)
    const secondStep =
      new URLSearchParams(window.location.search).get("secondStep") ||
      undefined;
    const thirdstep =
      new URLSearchParams(window.location.search).get("thirdStep") || undefined;
    const popUpId =
      new URLSearchParams(window.location.search).get("popUpId") || undefined;

    // alert(secondStep);
    // alert(thirdstep)
    // return
    const searchParams = new URLSearchParams(window.location.search);
    const cardData = expiryDate.split("/");
    const month = cardData[0];
    // const year = `20${cardData[1]}`;
    let year='';
    if(cardData[1]!=undefined){
      year = `20${cardData[1]}`;
    }else{
      year=''
    }
    const orderObj = {
      clientId: searchParams.get("clientId"),
      SubscriptionId: searchParams.get("subsId") || searchParams.get("subId"),
      tierId: searchParams.get("tierId"),
      type: "SUBSCRIPTION",
      anonId: searchParams.get("anonId"),
      numberOfLicense: searchParams.get("numberOfLicense"),
      secondStep,
      popUpId,
      thirdstep,
      isGift,
      sender,
      recieverMessage,
      recipientEmail,
      recipientName,
      cardType,
      userDetails: {
        name: userDetails.name,
        email: userDetails.email,
        phone: userDetails.phoneNumber,
        shippingAddress: address,
        secondaryPhone: userDetails.secondaryPhone,
        secondaryEmail: userDetails.secondaryEmail,
        countryCode: userDetails.countryCode,
        billingAddress: billingAddress,

        // userDetails.shippingAddress[indexOfShipingAddress],
        // billingAddress:userDetails.shippingAddress[indexOfShipingAddress],
        // userDetails?.shippingAddress[indexOfShipingAddress],
        // billingAddress:userDetails?.shippingAddress[indexOfShipingAddress],
      },
      renew,
      cyberSourceSessionId,
      routing,
      cardToken,
      cardNumber: cardNumber.replaceAll(/\s/g, ""),
      cardHolderName,
      month,
      year,
      setLoading: setbuttonLoading,
      clientContentId: searchParams.get("clientContentId") || "",
      setShowStepUp,
      setStepUpToken,
      couponCode,
      setStepUrl,
      setTransactionId,
      setInternelTransactionId,
      setCyberSourceSessionId,
      setCyberSourcedata,
    };
    callrzpSubscription(orderObj);
  };
  const checkSuccessCode = (response: any) => {
    return Math.floor(response.status / 100) === 2;
  };
  const detectCountry = async (data: any) => {
    const detectCountry = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/auth/detect-country`,
    });
    if (checkSuccessCode(detectCountry)) {
      const value = Country_flag_json?.find(
        (e) => e?.code === detectCountry?.data?.countryCode
      );
      // setcode(value?.dail_code);
      setUserDetails({ ...data, countryCode: value?.dail_code });
    }
  };
  useEffect(() => {
    cyberSourceSessionId.length > 10 && cyberSourceCheckout();
  }, [cyberSourceSessionId]);
  useMemo(() => {
if(routing.gateway==='CYBERSOURCE'){
  const script = document.createElement('script');
  const orgId=process.env.REACT_APP_ORGID
// alert('op9iuyttyio')
 
  script.type = 'text/javascript';
  script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${orgId}&session_id=${routing.merchantId}${randomUUid}`;
  script.async = true;
  document.head.appendChild(script);


  return () => {
    // Cleanup: Remove the script tag when the component unmounts
    document.head.removeChild(script);
  }
}
  }, [routing.gateway]);

  const dropdownRender = (menu: React.ReactElement) => (
    <div>
      <div className={style["dropDown-search"]}>
        <Input
          placeholder="Search..."
          onChange={(e) => SetInputValue(e.target.value)}
        />
        <img src={Search_icon} alt="Search_icon" />
      </div>
      {menu}
    </div>
  );
  const handleExpiryDateChange = (e: any) => {
    let input = e.target.value;

    // Remove any non-numeric characters, except for "/"
    input = input.replace(/[^\d/]/g, "");

    // Only add "/" if the input length is increasing
    if (
      input.length === 2 &&
      !input.includes("/") &&
      input.length > expiryDate.length
    ) {
      input = input + "/";
    }

    // Limit the input to 5 characters (MM/YY)
    if (input.length > 5) {
      input = input.slice(0, 5);
    }

    setExpiryDate(input);
  };
  const redirectToSLP = async () => {
    setLoading(true);
    try {
      const searchParams = new URLSearchParams(window.location.search);
      let rid =
        searchParams.get("rid") === "null" ? null : searchParams.get("rid");
      const redirecturl = searchParams.get("siteUrl") || "";
      const clientId = searchParams.get("clientId") || "";
      const ssoUrl = ssoRedirectUrl;
      const anonId = localStorage.getItem("anonId") || "";
      const paywallId = localStorage.getItem("paywallId") || "";
      const nonSLPClients = [
        "661907c2487ae1aba956dcc4",
        "663c631856e840159e994170",
      ];

      if (ssoUrl.length < 10 || !nonSLPClients.includes(clientId)) {
        try {
          const decodedRedirecturl = redirecturl
            ? decodeURIComponent(redirecturl)
            : "";
          const decodedSiteUrl = siteUrl ? decodeURIComponent(siteUrl) : "";
          const newRedirecturl = new URL(
            decodedRedirecturl || decodedSiteUrl || ""
          );
          console.log("Valid URL:", newRedirecturl.toString());
          newRedirecturl.searchParams.append("backClick", "true");
          window.location.href = newRedirecturl.href;
          return;
        } catch (error) {
          console.error("Invalid URL after decoding:", error);
        }
      }
      window.location.href = `${ssoUrl}?redirectUrl=${redirecturl}&clientId=${clientId}&anonId=${anonId}&contentId=null&paywallId=${paywallId}&back=true`;
    } finally {
      setLoading(false);
    }
  };

  const getUserDetials = async () => {
    const clientId =
      new URLSearchParams(window.location.search).get("clientId") || "";
    const userData = await API.payment.userDetails({
      clientId: clientId || "",
      accessToken: "zddf",
    });
    // alert('dkjhgiuk')
    setUserLoggedIn(true);
    const value = Country_flag_json?.find(
      (e) => e?.code === userData.data.detectedCountry
    );
    setUserDetails({ ...userData.data, countryCode: value?.dail_code });
    setLoading(false);
  };
  useEffect(() => {
    if (
      subsDetailsData?.gift?.gifting === "MANDATORY" &&
      subsDetailsData?.gift?.giftEnabled
    ) {
     if(showgift!=='false'){
      setIsGift(true);
     }
      setIsGiftMandatory(true);
      setRenew(false)
    } else {
      setIsGiftMandatory(false);
    }
  }, [subsDetailsData]);

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const authzCode = searchParams.get("authorizationCode");
      const client = searchParams.get("clientId");
      const rid = searchParams.get("rid");
      const couponCode = searchParams.get("couponCode") || "";
      const numberOfLicense = Number(searchParams.get("numberOfLicense"));
      const getData = await API.user.getCheckoutSettings({
        clientId: searchParams.get("clientId"),
      });
      setRedirects(getData.data.redirects);
      localStorage.setItem("tnc", getData.data.redirects.tnc);
      localStorage.setItem("privPolicy", getData.data.redirects.privPolicy);

      setCheckoutSetting(getData.data.result);
      let digitalTaxRate = 0;
      let physicalTaxRate = 0;
      const includeTaxInPrice = getData?.data?.includeTaxInPrice;
      // const taxdata=getData?.data?.taxConfig;
      if (getData?.data.taxConfig[0]?.categories[0] === "DIGITAL") {
        digitalTaxRate = getData?.data?.taxConfig[0]?.taxes[0]?.taxRate || 0;
      }
      if (getData?.data.taxConfig[0]?.categories[0] === "PHYSICAL") {
        physicalTaxRate = getData?.data?.taxConfig[0]?.taxes[0]?.taxRate || 0;

        // const digitalTaxRate=getData?.data?.taxConfig[1]?.taxes[0]?.taxRate||0;
      }
      if (getData?.data.taxConfig.length > 1) {
        if (getData?.data.taxConfig[1]?.categories[0] === "DIGITAL") {
          digitalTaxRate = getData?.data?.taxConfig[1]?.taxes[0]?.taxRate || 0;
        }
        if (getData?.data.taxConfig[1]?.categories[0] === "PHYSICAL") {
          physicalTaxRate = getData?.data?.taxConfig[1]?.taxes[0]?.taxRate || 0;

          // const digitalTaxRate=getData?.data?.taxConfig[1]?.taxes[0]?.taxRate||0;
        }
      }
      // console.log(physicalTaxRate,digitalTaxRate,'dsjhiudshfcoi')

      setSSOredirectUrl(getData.data.ssoRedirectUrl);
      localStorage.setItem("ssoRedirectUrl", getData.data.ssoRedirectUrl);

      if (authzCode) {
        const resp = await fetch(
          `${process.env.REACT_APP_API_URL_V2}/authorization/login?clientId=${client}&rid=${rid}`,
          {
            headers: {
              authorization: `Bearer ${authzCode}`,
            },
            method: "GET",
          }
        );
        const data = await resp.json();
        removeURLParam("authorizationCode");
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        // setLogged(true);
      }
      let accessToken = localStorage.getItem("accessToken");

      if (accessToken != undefined && accessToken.length > 18) {
        setTimeout(() => {
          getUserDetials();
        }, 400);
        const queryData = new URLSearchParams(window.location.search);
        const subsId = queryData.get("subsId") || queryData.get("subId") || "";
        const tierId = queryData.get("tierId") || "";
        const clientId = queryData.get("clientId") || "";
        const data = await API.user.getSubsDetails({
          subsId,
          tierId,
          clientId,
          accessToken: "sdfd",
          numberOfLicense: numberOfLicense,
          couponCode,
        });
        // window.location.href=`${process.env.REACT_APP_SUB_URL}/alreadyPurchased?clientId=${clientId}&duration=${data.data.duration}&title=${data.data.name}`

        if (data.data.isPurchaseExists) {
          setisPurchased(true);
        }

        const routingData = await callRoutingConfig();
        //hardcoding to check for cybersource
        //  routingData.gateway='CYBERSOURCE'
        if (queryData.get("amount")) {
          setSubsDetailsData({
            ...data.data,
            convertedPrice: queryData.get("amount"),
            proRataDiscount: 0,
            originalPrice: queryData.get("amount"),
            currency: queryData.get("currency"),
          });
          data.data.convertedPrice = queryData.get("amount");
          data.data.originalPrice = queryData.get("amount");
          data.data.currency = queryData.get("currency");
        } else {
          setSubsDetailsData(data.data);
        }
        if (!includeTaxInPrice) {
          if (data.data.digital) {
            if (digitalTaxRate) {
              settax((digitalTaxRate / 100) * data.data.convertedPrice);
              setRenewAmount(
                data.data.originalPrice +
                  (digitalTaxRate / 100) * data.data.originalPrice
              );

              // setTaxBeforeDiscount((digitalTaxRate / 100) * data.data.originalPrice)
            } else {
              setRenewAmount(data.data.originalPrice);
            }
          }
          if (data.data.physical) {
            if (physicalTaxRate) {
              settax((physicalTaxRate / 100) * data.data.convertedPrice);
              setRenewAmount(
                data.data.originalPrice +
                  (physicalTaxRate / 100) * data.data.originalPrice
              );

              // setTaxBeforeDiscount((physicalTaxRate / 100) * data.data.convertedPrice);
            } else {
              setRenewAmount(data.data.originalPrice);
            }
          }
          if (data.data.epaper) {
            if (digitalTaxRate) {
              settax((digitalTaxRate / 100) * data.data.convertedPrice);
              setRenewAmount(
                data.data.originalPrice +
                  (digitalTaxRate / 100) * data.data.originalPrice
              );
            } else {
              setRenewAmount(data.data.originalPrice);
            }
          }
        } else {
          if (data.data.digital) {
            if (digitalTaxRate) {
              let tax1 =
                (data.data.convertedPrice * digitalTaxRate) /
                (100 + digitalTaxRate);
              setInclusiveTax(tax1);
              setSubsDetailsData({
                ...data.data,
              });
            }
            setRenewAmount(data.data.originalPrice);
          }
          if (data.data.physical) {
            setRenewAmount(data.data.originalPrice);

            if (physicalTaxRate) {
              let tax1 =
                (data.data.convertedPrice * physicalTaxRate) /
                (100 + physicalTaxRate);
              // settax(tax);
              setInclusiveTax(tax1);

              setSubsDetailsData({
                ...data.data,
              });
              // settax((physicalTaxRate / 100) * data.data.convertedPrice);
            }
          }
          if (data.data.epaper) {
            console.log("kjdshui6");

            setRenewAmount(data.data.originalPrice);

            if (digitalTaxRate) {
              let tax1 =
                (data.data.convertedPrice * digitalTaxRate) /
                (100 + digitalTaxRate);
              setInclusiveTax(tax1);
              setSubsDetailsData({
                ...data.data,
                // convertedPrice: convertedPrice,
              });

              // settax((digitalTaxRate / 100) * data.data.convertedPrice);
            }
          }
        }

        if (data.data.billingCycleType === "RECURRING") {
          setshowRenewline(true);

          setShowRenew(false);
          // setRenew(true);
          if(showgift){
            setRenew(false)
          }else{
            setRenew(true)
          }
        }
        if (data.data.billingCycleType === "ONE_TIME") {
          setShowRenew(false);
          setRenew(false);
        }
        if (data.data.billingCycleType === "BOTH_DEFAULT_UNCHECKED_RECURRING") {
          setShowRenew(true);
          setRenew(false);
        }
        if (data.data.billingCycleType === "BOTH_DEFAULT_CHECKED_RECURRING") {
          setShowRenew(true);
          setRenew(true);
        }

        //  routingData.gateway='CYBERSOURCE'

        if (routingData.gateway === "CYBERSOURCE") {
          const data = await API.payment.getCardDetails({ clientId });
          data.data.cardDetails.map((card: any) => {
            if (card.isDefault === true) {
              setCardToken(card._id);
            }
          });
          setCardDetails([...data.data.cardDetails]);
        }
        sendviewEvent(data.data);
      } else {
        const queryData = new URLSearchParams(window.location.search);
        const subsId = queryData.get("subsId") || queryData.get("subId") || "";
        const tierId = queryData.get("tierId") || "";
        const clientId = queryData.get("clientId") || "";
        // const querySelector=
        const data = await API.user.getSubsDetailsAnon({
          subsId,
          tierId,
          clientId,
          numberOfLicense: numberOfLicense,
          couponCode: couponCode,
        });
        setSubsDetailsData(data.data);
        setLoading(false);
      }
    })();
  }, [refreshData]);
  function formatNumber(num: number) {
    // Check if the number is a whole number
    if (num % 1 === 0) {
      return num; // Return as is for whole numbers
    }
    // Fix the number to 2 decimal places for non-whole numbers
    return parseFloat(num.toFixed(2));
  }

  const searchParams2 = new URLSearchParams(window.location.search);
  const siteUrl = searchParams2.get("siteUrl") || undefined;

  const handleCardNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const onlyNumbers = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedNumber = onlyNumbers.replace(/(.{4})/g, "$1 ").trim(); // Format as 'XXXX XXXX XXXX XXXX'

    setCardNumber(formattedNumber); // Update card number
    console.log(detectCardType(onlyNumbers),'jkytuioiuygf');

    setCardType(detectCardType(onlyNumbers)); // Detect and update card type
  };
  const contactInput =
    checkoutSetting.contactInfo.DOB.isRequired ||
    checkoutSetting.contactInfo.email.isRequired ||
    checkoutSetting.contactInfo.gender.isRequired ||
    checkoutSetting.contactInfo.mobile.isRequired ||
    checkoutSetting.contactInfo.nameInput.isRequired;

  if (isStripe === true) {
    return (
      <SubsStripePayment
        subscriptionContentDetails={subscriptionContentDetails}
      />
    );
  }

  if (isPaypal === true) {
    return (
      <PaypalPayment subscriptionContentDetails={subscriptionContentDetails} />
    );
  }
  if (showStepUp === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StepUpForm
          stepUpToken={stepUpToken}
          transactionId={transactionId}
          cardToken={cardToken}
          internelTYransactionId={internelTYransactionId}
          stepUpUrl={stepUpUrl}
          cyberSourceSessionId={randomUUid}
        />
      </div>
    );
  }
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  if (isPurchased) {
    return (
      <Alreadypurchased
        userDetails={userDetails}
        logoUrl={routing.logoUrl}
        tnc={redirects.tnc}
        privPolicy={redirects.privPolicy}
      />
    );
  }
  return (
    
    <>
      <ApplyCoupon setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      <AddAddress
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        userDetails={userDetails}
        setUserDetails={setUserDetails}
      />

      <Benifits
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        benifits={subsDetailsData}
      />
      <SavedAddress
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        userDetails={userDetails}
        setIndexOfShipingAddress={setIndexOfShipingAddress}
        indexOfShipingAddress={indexOfShipingAddress}
        setUserDetails={setUserDetails}
      />
      <AddCardDetails
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
      />
      <div className={style["main-container"]}>
        <div className={style["header-logo"]}>
          <img
            className={style["image-logo"]}
            src={routing.logoUrl}
            alt="Demo_Image"
          />
        </div>
        <div style={{ flexGrow: 1 }}>
          <div className={style["container"]}>
            <div className={style["sub-container"]}>
              <div className={style["first-container"]}>
                {contactInput && (
                  <div className={style["box1-container"]}>
                    <div className={style["contact-details"]}>
                      <div className={style["contact-heading"]}>
                        <DropDownArrow
                          onClick={() => setContantDetails(!contantDetails)}
                          style={{
                            transform: contantDetails
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                            cursor: "pointer",
                          }}
                        />
                        <div className={style["contact-heading-text"]}>
                          Contact Details
                        </div>
                      </div>
                      {!userLoggedIn && (
                        <div className={style["already-have-account"]}>
                          Already Have an account?{" "}
                          <span
                            onClick={() => {
                              redirectToLogin();
                            }}
                            className={style["login"]}
                          >
                            Login
                          </span>
                        </div>
                      )}
                    </div>
                    {contantDetails && (
                      <>
                        {checkoutSetting.contactInfo.nameInput.isRequired && (
                          <div className={style["first-name-input"]}>
                            <Input
                              value={userDetails?.name}
                              onChange={(e) => {
                                setUserDetails({
                                  ...userDetails,
                                  name: e.target.value.trimStart(),
                                });
                              }}
                              placeholder="First Name & Last Name"
                            />
                          </div>
                        )}
                        <div className={style["input-text-container1"]}>
                          {checkoutSetting.contactInfo.email.isRequired && (
                            <div
                              className={style["email-id-input"]}
                              style={{
                                width:
                                  checkoutSetting.contactInfo.email
                                    .isRequired &&
                                  checkoutSetting.contactInfo.mobile.isRequired
                                    ? ""
                                    : "100%",
                              }}
                            >
                              <Input
                                disabled={userDetails.isEmailVerified}
                                onChange={(e) => {
                                  setUserDetails({
                                    ...userDetails,
                                    secondaryEmail: e.target.value,
                                  });
                                }}
                                value={
                                  userDetails?.email ||
                                  userDetails?.secondaryEmail
                                }
                                placeholder="Email id"
                              />
                            </div>
                          )}
                          {checkoutSetting.contactInfo.mobile.isRequired && (
                            <div
                              className={style["input-container"]}
                              style={{
                                width:
                                  checkoutSetting.contactInfo.email
                                    .isRequired &&
                                  checkoutSetting.contactInfo.mobile.isRequired
                                    ? ""
                                    : "auto",
                              }}
                            >
                              <img src={Mobile_icon} alt="Mobile_icon" />
                              <div className={style["dropDown-container"]}>
                                <Select
                                  disabled={userDetails.isPhoneVerified}
                                  value={userDetails.countryCode}
                                  placeholder={userDetails.countryCode || "+91"}
                                  dropdownRender={dropdownRender}
                                  onChange={(value) =>
                                    setUserDetails({
                                      ...userDetails,
                                      countryCode: value,
                                    })
                                  }
                                  popupMatchSelectWidth={250}
                                  filterOption={false}
                                  suffixIcon={<SelectArrowIcon />}
                                  className={dropDownStyle["common-select-box"]}
                                  popupClassName={
                                    dropDownStyle["common-dropdown-select"]
                                  }
                                  optionLabelProp="label"
                                >
                                  {filteredOptions.map((option, index) => (
                                    <Select.Option
                                      key={index}
                                      value={option.dail_code}
                                      label={option.dail_code}
                                      className={
                                        dropDownStyle["dropdown-options"]
                                      }
                                    >
                                      <div className={style["dropdown-flag"]}>
                                        <div
                                          className={style["flag-code"]}
                                          style={{ fontSize: "12px" }}
                                        >
                                          {option.dail_code}
                                        </div>
                                        <div className={style["flag-name"]}>
                                          {option.name}
                                        </div>
                                        <div className={style["flag"]}>
                                          {option.flag}
                                        </div>
                                      </div>
                                    </Select.Option>
                                  ))}
                                </Select>
                              </div>
                              <div className={style["vertical-line"]}></div>
                              <Input
                                disabled={userDetails.isPhoneVerified}
                                value={
                                  userDetails.phoneNumber ||
                                  userDetails.secondaryPhone
                                }
                                onChange={(e) => {
                                  const numericValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  setUserDetails({
                                    ...userDetails,
                                    secondaryPhone: numericValue,
                                  });
                                }}
                                placeholder={"Mobile"}
                              />
                            </div>
                          )}
                        </div>
                        <div className={style["input-text-container2"]}>
                          {checkoutSetting.contactInfo.DOB.isRequired && (
                            <DatePicker
                              onChange={(e) => {
                                if (e) {
                                  const utcDate = new Date(
                                    Date.UTC(
                                      e.year(),
                                      e.month(),
                                      e.date(),
                                      e.hour(),
                                      e.minute(),
                                      e.second(),
                                      e.millisecond()
                                    )
                                  ).toISOString();

                                  setUserDetails({
                                    ...userDetails,
                                    dateOfBirth: utcDate,
                                  });
                                }
                              }}
                              placeholder={
                                userDetails?.dateOfBirth?.text ||
                                "Date of Birth"
                              }
                              inputReadOnly={true}
                              defaultValue={
                                userDetails.dateOfBirth
                                  ? dayjs(userDetails.dateOfBirth)
                                  : undefined
                              }
                              suffixIcon={<CalenderIcon />}
                              className={style["date-picker-input"]}
                              style={{
                                width:
                                  checkoutSetting.contactInfo.DOB.isRequired &&
                                  checkoutSetting.contactInfo.gender.isRequired
                                    ? ""
                                    : "100%",
                              }}
                              disabledDate={disableFutureAndToday}
                            />
                          )}
                          {checkoutSetting.contactInfo.gender.isRequired && (
                            <div
                              className={style["gender-input"]}
                              style={{
                                width:
                                  checkoutSetting.contactInfo.DOB.isRequired &&
                                  checkoutSetting.contactInfo.gender.isRequired
                                    ? ""
                                    : "100%",
                              }}
                            >
                              <CustomDropDownForSelect
                                onChangeForSelect={(e: any) =>
                                  setUserDetails({ ...userDetails, gender: e })
                                }
                                options={{ array: GenderArray, key: "value" }}
                                defaultValue={userDetails?.gender || undefined}
                                label={{ array: GenderArray, key: "label" }}
                                placeholder="Gender"
                                customStyle={{
                                  height: "40px",
                                  border: "1px solid #dedede",
                                }}
                                hoverColor="#E6E6E6"
                                dropdownStyle={{ zIndex: 1050 }}
                                // onChangeForSelect={()}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
                {showAddress && (
                  <div>
                    <div
                      className={style["contact-heading-text"]}
                      style={{ margin: "16px 0px" }}
                    >
                      Delivery Address
                    </div>

                    {typeof userDetails?.shippingAddress?.[0] === "object" &&
                      userDetails?.shippingAddress?.[0] !== null && (
                        <div>
                          <div className={style["address-container"]}>
                            <div className={style["address-name"]}>
                              {
                                userDetails?.shippingAddress[
                                  indexOfShipingAddress
                                ].name
                              }
                            </div>
                            <div className={style["address"]}>
                              {
                                userDetails?.shippingAddress[
                                  indexOfShipingAddress
                                ].area
                              }
                              {
                                userDetails?.shippingAddress[
                                  indexOfShipingAddress
                                ].state
                              }
                              {
                                userDetails?.shippingAddress[
                                  indexOfShipingAddress
                                ].country
                              }
                              {
                                userDetails?.shippingAddress[
                                  indexOfShipingAddress
                                ].pincode
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    {typeof userDetails?.shippingAddress?.[0] === "object" &&
                      userDetails?.shippingAddress?.[0] !== null && (
                        <div className={style["add-and-change-address"]}>
                          <div
                            className={style["add-address"]}
                            onClick={() =>
                              setIsModalOpen({
                                ...isModalOpen,
                                addressModal: true,
                              })
                            }
                          >
                            Add Address
                          </div>
                          <div
                            className={style["add-address"]}
                            onClick={() =>
                              setIsModalOpen({
                                ...isModalOpen,
                                savedAddressModal: true,
                              })
                            }
                          >
                            Change address
                          </div>
                        </div>
                      )}
                    {userDetails?.shippingAddress?.length === 0 && (
                      <div style={{ marginTop: "16px" }}>
                        <div className={style["input-text-container1"]}>
                          <div className={style["pincode-input"]}>
                            <Input
                              value={address.pincode}
                              onChange={(e) => {
                                const value = e.target.value;
                                const isValid = /^\d{0,10}$/.test(value);
                                if (isValid) {
                                  setAddress({
                                    ...address,
                                    pincode: value,
                                  });
                                }
                              }}
                              placeholder="PIN / ZIP Code"
                            />
                          </div>
                          <div className={style["pincode-input"]}>
                            <Input
                              value={address.state}
                              onChange={(e) => {
                                const value = e.target.value;
                                const isValid = /^[a-zA-Z\s]{0,100}$/.test(
                                  value
                                );
                                if (isValid) {
                                  setAddress({
                                    ...address,
                                    state: value,
                                  });
                                }
                              }}
                              placeholder="State"
                            />
                          </div>
                          <div className={style["pincode-input"]}>
                            <Input
                              value={address.country}
                              onChange={(e) => {
                                const value = e.target.value;
                                const isValid = /^[a-zA-Z\s]{0,100}$/.test(
                                  value
                                );
                                if (isValid) {
                                  setAddress({
                                    ...address,
                                    country: value,
                                  });
                                }
                              }}
                              placeholder="Country"
                            />
                          </div>
                        </div>
                        <div className={style["address-name-input"]}>
                          <Input
                            onChange={(e) => {
                              setAddress({ ...address, name: e.target.value });
                            }}
                            placeholder="First Name & Last Name"
                          />
                        </div>
                        <div className={style["address-name-input"]}>
                          <Input
                            onChange={(e) => {
                              setAddress({ ...address, area: e.target.value });
                            }}
                            placeholder="Full Address (House number, area... etc)"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {checkoutSetting.billingAddress && !billingSameAsShipping && (
                  <div>
                    <div
                      className={style["contact-heading-text"]}
                      style={{ margin: "16px 0px" }}
                    >
                      Billing Address
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      <div className={style["input-text-container1"]}>
                        <div className={style["pincode-input"]}>
                          <Input
                            value={billingAddress.pincode}
                            onChange={(e) => {
                              const value = e.target.value;
                              const isValid = /^\d{0,10}$/.test(value);
                              if (isValid) {
                                setBillingAddress({
                                  ...billingAddress,
                                  pincode: value,
                                });
                              }
                            }}
                            placeholder="PIN / ZIP Code"
                          />
                        </div>
                        <div className={style["pincode-input"]}>
                          <Input
                            value={billingAddress.state}
                            onChange={(e) => {
                              const value = e.target.value;
                              const isValid = /^[a-zA-Z\s]{0,100}$/.test(value);
                              if (isValid) {
                                setBillingAddress({
                                  ...billingAddress,
                                  state: e.target.value,
                                });
                              }
                            }}
                            placeholder="State"
                          />
                        </div>
                        <div className={style["pincode-input"]}>
                          <Input
                            value={billingAddress.country}
                            onChange={(e) => {
                              const value = e.target.value;
                              const isValid = /^[a-zA-Z\s]{0,100}$/.test(value);
                              if (isValid) {
                                setBillingAddress({
                                  ...billingAddress,
                                  country: e.target.value,
                                });
                              }
                            }}
                            placeholder="Country"
                          />
                        </div>
                      </div>
                      <div className={style["address-name-input"]}>
                        <Input
                          value={billingAddress.name}
                          onChange={(e) => {
                            setBillingAddress({
                              ...billingAddress,
                              name: e.target.value,
                            });
                          }}
                          placeholder="First Name & Last Name"
                        />
                      </div>
                      <div className={style["address-name-input"]}>
                        <Input
                          value={billingAddress.area}
                          onChange={(e) => {
                            setBillingAddress({
                              ...billingAddress,
                              area: e.target.value,
                            });
                          }}
                          placeholder="Full Address (House number, area... etc)"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {checkoutSetting.billingAddress &&
                  (checkoutSetting.deliveryAddress.customization
                    .adsFreeSubscription.isRequired ||
                    checkoutSetting.deliveryAddress.customization
                      .digitalSubscription.isRequired ||
                    checkoutSetting.deliveryAddress.customization
                      .digitalSubscription.isRequired) && (
                    <div
                      className={style["checkbox"]}
                      style={
                        billingSameAsShipping
                          ? { margin: "12px 0px 0px 0px" }
                          : {}
                      }
                    >
                      <Checkbox
                        onClick={() => {
                          setbillingSameAsShipping(!billingSameAsShipping);
                          setBillingAddress({ ...address });
                        }}
                      />
                      <div>
                        <div className={style["billing-address"]}>
                          Billing Address
                        </div>
                        <div className={style["same-as-address"]}>
                          Same as Delivery Address
                        </div>
                      </div>
                    </div>
                  )}
                {subsDetailsData?.gift?.giftEnabled &&
                  (checkoutSetting.giftingDetails.message.isRequired === true ||
                    checkoutSetting.giftingDetails.recipientEmailPhone
                      .isRequired === true ||
                    checkoutSetting.giftingDetails.recipientName.isRequired ===
                      true) &&
                  !(showgift === "false") && (
                    <div
                      className={style["checkbox"]}
                      style={{
                        display: "block",
                        padding: "12px 12px 2px",
                        margin: "20px 0px",
                      }}
                    >
                      <div
                        className={style["flex-center"]}
                        style={{ gap: "10px" }}
                      >
                        <Checkbox
                          checked={isGift}
                          onClick={() =>
                            !isGiftMandatory &&
                            (setIsGift(!isGift), setRenew(false))
                          }
                          disabled={isGiftMandatory}
                        />
                        <div className={style["contact-heading-text"]}>
                          This is a Gift
                        </div>
                      </div>
                      <div style={{ marginTop: "16px" }}>
                        <div className={style["input-text-container1"]}>
                          {checkoutSetting.giftingDetails.recipientName
                            .isRequired && (
                            <div className={style["gift-input"]}>
                              <Input
                                value={recipientName}
                                onChange={(e) => {
                                  setrecipientName(e.target.value);
                                }}
                                placeholder="Recipient’s Name"
                              />
                            </div>
                          )}
                          {checkoutSetting.giftingDetails.recipientEmailPhone
                            .isRequired && (
                            <div className={style["gift-input"]}>
                              <Input
                                value={recipientEmail}
                                onChange={(e) => {
                                  setrecipientEmail(e.target.value);
                                }}
                                placeholder="Recipient’s Email ID"
                              />
                            </div>
                          )}
                        </div>
                        {checkoutSetting.giftingDetails.message.isRequired && (
                          <div className={style["address-name-input"]}>
                            <TextArea
                              value={recieverMessage}
                              onChange={(e) => {
                                setRecieverMessage(e.target.value);
                              }}
                              placeholder="Personalised message"
                              style={{ height: "80px" }}
                            />
                          </div>
                        )}
                        <div className={style["address-name-input"]}>
                          <Input
                            value={sender}
                            onChange={(e) => {
                              setsender(e.target.value);
                            }}
                            placeholder="From"
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>

              <div className={style["second-container"]}>
                <div style={{ flexGrow: 1 }}>
                  <div
                    className={style["order-summary"]}
                    style={{
                      backgroundColor: orderSummary ? "#ffffff" : "#f0f0f0",
                    }}
                  >
                    <div className={style["order-details"]}>
                      <div className={style["contact-heading"]}>
                        <div className={style["order-heading-text"]}>
                          Order Summary
                        </div>
                        <DropDownArrow
                          onClick={() => setOrderSummary(!orderSummary)}
                          style={{
                            transform: orderSummary
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <div className={style["order-price"]}>
                        {getCurrencySymbol(subsDetailsData.currency)}
                        {formatNumber(+subsDetailsData.convertedPrice + tax)}
                      </div>
                    </div>
                    {orderSummary && (
                      <>
                        <hr
                          style={{
                            border: "0.5px solid rgba(135, 135, 135, 0.1)",
                          }}
                        />
                        <div>
                          <div
                            style={{ alignItems: "start", padding: "0px 8px" }}
                          >
                            <div
                              className={style["flex-justify-space-between"]}
                            >
                              <div className={style["subs-duration"]}>
                                Duration:{" "}
                                {getWordsFromDuration(
                                  subsDetailsData.duration,
                                  subsDetailsData.durationType
                                )}
                              </div>
                              {siteUrl && (
                                <div
                                  onClick={() => redirectToSLP()}
                                  className={style["change-plan"]}
                                >
                                  Change Plan
                                </div>
                              )}
                            </div>
                            <div className={style["subs-plan-name"]}>
                              {subsDetailsData.name}
                            </div>
                            {subsDetailsData.benefits.length > 0 && (
                              <div
                                onClick={() => {
                                  setIsModalOpen({
                                    ...isModalOpen,
                                    benifits: true,
                                  });
                                }}
                                className={style["view-benefits-offers"]}
                              >
                                View all benefits & offers
                              </div>
                            )}
                          </div>
                          <div
                            style={{ marginTop: "20px", padding: "0px 10px" }}
                          >
                            {/* <div className={style['flex-justify-space-between']} style={{ marginTop: '10px' }}>
                            <div className={style['apply-coupon-text']}>Apply Coupon</div>
                            <div className={style['more-coupon-text']} onClick={() => setIsModalOpen({ ...isModalOpen, applyCouponModal: true })}>More Coupons</div>
                          </div> */}
                            {/* <div className={style['flex-justify-space-between']} style={{ marginTop: '10px' }}>
                            <div className={style['apply-coupon-text']}>Have a Coupon Code?</div>
                            <div className={style['more-coupon-text']} onClick={() => setIsModalOpen({ ...isModalOpen, applyCouponModal: true })}>Apply Coupon</div>
                          </div> */}
                            {/* <div className={style['flex-justify-space-between']} style={{ marginTop: '10px' }}>
                            <div className={style['flex-center']} style={{ gap: '10px' }}>
                              <div className={style['apply-coupon-code']}>HGHGHGHG342</div>
                              <div className={style['apply-coupon-saved-rupess']}>Saved ₹500 </div>
                            </div>
                            <div className={style['remove-coupon']}>Remove</div>
                          </div> */}
                          </div>
                          <div
                            style={{ marginTop: "20px", padding: "0px 16px" }}
                          >
                            <div
                              className={style["flex-justify-space-between"]}
                              style={{ marginBottom: "10px" }}
                            >
                              <div className={style["apply-coupon-text"]}>
                                Price
                              </div>
                              <div className={style["price-amount"]}>
                                {getCurrencySymbol(subsDetailsData.currency)}
                                {subsDetailsData.originalPrice}
                              </div>
                            </div>
                            {subsDetailsData.proRataDiscount > 0 && (
                              <div
                                className={style["flex-justify-space-between"]}
                                style={{ marginBottom: "10px" }}
                              >
                                <div className={style["apply-coupon-text"]}>
                                  Pro-Rata Discount
                                </div>
                                <div
                                  className={style["price-amount"]}
                                  style={{ color: "#008045" }}
                                >
                                  -{getCurrencySymbol(subsDetailsData.currency)}
                                  {subsDetailsData.proRataDiscount}
                                </div>
                              </div>
                            )}
                            {/* {tax>0&& ( <div
                                className={style["flex-justify-space-between"]}
                                style={{ marginBottom: "10px" }}
                              >
                                <div className={style["apply-coupon-text"]}>
                                 Tax
                                </div>
                                <div
                                  className={style["price-amount"]}
                                  // style={{ color: "#008045" }}
                                >
                                  +{subsDetailsData.currency}{formatNumber(tax)}
                                  
                                </div>
                              </div>)} */}
                            {/* <div className={style['flex-justify-space-between']} style={{ marginBottom: '10px' }}>
                            <div className={style['apply-coupon-text']}>GST @10%</div>
                            <div className={style['price-amount']}>₹95.00</div>
                          </div> */}
                            {subsDetailsData.couponDiscountPrice > 0 && (
                              <div
                                className={style["flex-justify-space-between"]}
                                style={{ marginBottom: "10px" }}
                              >
                                <div className={style["apply-coupon-text"]}>
                                  Coupon Discount
                                </div>
                                <div
                                  className={style["price-amount"]}
                                  style={{ color: "#008045" }}
                                >
                                  -{" "}
                                  {getCurrencySymbol(subsDetailsData.currency)}
                                  {formatNumber(
                                    subsDetailsData?.couponDiscountPrice
                                  )}
                                </div>
                              </div>
                            )}
                            <hr
                              style={{
                                border: "0.5px solid rgba(135, 135, 135, 0.1)",
                              }}
                            />
                            <div
                              className={style["flex-justify-space-between"]}
                              style={{ marginBottom: "10px" }}
                            >
                              <div className={style["price-amount"]}>Total</div>
                              <div className={style["price-amount"]}>
                                {getCurrencySymbol(subsDetailsData.currency)}
                                {formatNumber(+subsDetailsData.convertedPrice)}
                              </div>
                            </div>
                            {/* {subsDetailsData.couponDiscountPrice&&( <div className={style['flex-justify-space-between']} style={{ marginBottom: '10px' }}>
                            <div className={style['apply-coupon-text']}>Coupon Discount</div>
                            <div className={style['price-amount']} style={{ color: '#008045' }}>-{subsDetailsData.couponDiscountPrice}</div>
                          </div>)} */}
                            {tax > 0 && (
                              <div
                                className={style["flex-justify-space-between"]}
                                style={{ marginBottom: "10px" }}
                              >
                                <div className={style["apply-coupon-text"]}>
                                  Tax
                                </div>
                                <div
                                  className={style["price-amount"]}
                                  // style={{ color: "#008045" }}
                                >
                                  +{getCurrencySymbol(subsDetailsData.currency)}
                                  {formatNumber(tax)}
                                </div>
                              </div>
                            )}
                            {inclusiveTax > 0 && (
                              <div
                                className={style["flex-justify-space-between"]}
                                style={{ marginBottom: "10px" }}
                              >
                                <div className={style["apply-coupon-text"]}>
                                  Tax (Included in Total) :
                                </div>
                                <div
                                  className={style["price-amount"]}
                                  // style={{ color: "#008045" }}
                                >
                                  {getCurrencySymbol(subsDetailsData.currency)}
                                  {formatNumber(inclusiveTax)}
                                </div>
                              </div>
                            )}
                            <hr
                              style={{
                                border: "0.5px solid rgba(135, 135, 135, 0.1)",
                              }}
                            />
                            <div
                              className={style["flex-justify-space-between"]}
                              style={{ marginBottom: "10px" }}
                            >
                              <div className={style["price-amount"]}>
                                To Pay
                              </div>
                              <div className={style["price-amount"]}>
                                {getCurrencySymbol(subsDetailsData.currency)}
                                {formatNumber(
                                  +subsDetailsData.convertedPrice + tax
                                )}
                              </div>
                            </div>
                            {showRenew && !isGift && (
                              <div
                                className={style["flex-center"]}
                                style={{ gap: "6px", marginTop: "20px" }}
                              >
                                <Switch
                                  checked={renew}
                                  onChange={(checked) => {
                                    setRenew(checked);
                                  }}
                                  size="small"
                                />
                                <div className={style["renew-text"]}>
                                  Renew automatically after{" "}
                                  {getWordsFromDuration(
                                    subsDetailsData.duration,
                                    subsDetailsData.durationType
                                  )}{" "}
                                  at{" "}
                                  <span className={style["renew-price"]}>
                                    {getCurrencySymbol(
                                      subsDetailsData.currency
                                    )}
                                    {formatNumber(+renewAmount)}
                                  </span>
                                </div>
                              </div>
                            )}
                            {showRenewline && !isGift && (
                              <div className={style["renew-text"]}>
                                Renew automatically after{" "}
                                {getWordsFromDuration(
                                  subsDetailsData.duration,
                                  subsDetailsData.durationType
                                )}{" "}
                                at{" "}
                                <span className={style["renew-price"]}>
                                  {getCurrencySymbol(subsDetailsData.currency)}
                                  {formatNumber(+renewAmount)}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* will do when cyber source is added */}
                  <div className={style["payment-method-container"]}>
                    {/* <div className={style['select-payment']}>Select Payment Method</div> */}
                    {/* <div className={style['payment-method']}>
                    <div className={style['apple-pay']}>
                      <ApplePay />
                    </div>
                    <div className={style['google-pay']}>
                      <GooglePay />
                    </div>
                  </div> */}
                  </div>
                  {/* <div className={style['flex-justify-center']} style={{ margin: '16px 0px 30px 0px' }}>
                  <DividerIcon />
                </div> */}
                  {routing.gateway === "CYBERSOURCE" && renew && (
                    <>
                      {cardDetails.length > 0 && (
                        <div
                          className={style["contact-heading-text"]}
                          style={{ margin: "16px 0px" }}
                        >
                          Saved Cards
                        </div>
                      )}
                      {cardDetails.map((card: any, index: number) => (
                        <div
                          onClick={() => {
                            setCardToken(card._id);
                          }}
                          style={{
                            border:
                              cardToken === card._id ? "1px solid black" : "",
                          }}
                          className={style["select-card"]}
                        >
                          <div
                            className={style["flex-center"]}
                            style={{ gap: "10px" }}
                          >
                            <div className={style["card-number"]}>
                              **** {card.cardDetails.number.slice(-4)}
                              {/* {card.cardDetails.number} */}
                            </div>
                            <div className={style["line"]}></div>
                            <div className={style["card-number"]}>
                              {card?.nameOnCard}
                            </div>
                          </div>
                          <div
                            className={style["flex-center"]}
                            style={{ gap: "10px" }}
                          >
                            <div className={style["card-number"]}>
                              {/* {card.cardDetails.cardType} */}
                            </div>
                            <img
                              src={handleCardType(card.cardDetails.cardType)}
                              alt="AmericanExpressIcon"
                              className={style["card-img"]}
                            />
                          </div>
                        </div>
                      ))}
                      {cardDetails.length > 0 && (
                        <div
                          className={style["add-card"]}
                          onClick={() =>
                            setIsModalOpen({
                              ...isModalOpen,
                              cardDetailsModal: true,
                            })
                          }
                        >
                          <AddPlusIcon />
                          <div>Add Card</div>
                        </div>
                      )}

                      {cardDetails.length === 0 && (
                        <div className={style["card-form-container"]}>
                          <div className={style["label-name"]}>
                            Credit / Debit Card Details
                          </div>
                          <div style={{ marginBottom: "16px" }}>
                            <div className={style["first-name-input"]}>
                              <Input
                                placeholder="Full Name on the Card"
                                value={cardHolderName}
                                onChange={(e) =>
                                  setCardHolderName(e.target.value.trimStart())
                                }
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: "16px" }}>
                            <div className={style["first-name-input"]}>
                              <Input
                                value={cardNumber}
                                onChange={handleCardNumberChange}
                                placeholder="Card Number"
                                maxLength={19}
                              />
                            </div>
                          </div>
                          <div className={style["expiration-date"]}>
                            <div style={{ width: "80%" }}>
                              <div className={style["cvv-input"]}>
                                <Input
                                  onChange={handleExpiryDateChange}
                                  placeholder="MM/YY"
                                  value={expiryDate}
                                  maxLength={5}
                                />
                              </div>
                            </div>
                            <div style={{ width: "20%" }}>
                              <div className={style["cvv-input"]}>
                                <Input
                                  value={securityCode}
                                  onChange={(e) => {
                                    setSecurityCode(e.target.value);
                                  }}
                                  placeholder="CVV"
                                  style={{ textAlign: "center" }}
                                  maxLength={3}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className={style["save-card"]}>
                        <Checkbox />
                        <div>Save card details with Razer pay gateway</div>
                      </div> */}
                        </div>
                      )}
                    </>
                  )}
                  {routing.gateway === "CYBERSOURCE" &&
                    !renew &&
                    subsDetailsData.convertedPrice > 0 && (
                      <>
                        {cardDetails.length > 0 && (
                          <div
                            className={style["contact-heading-text"]}
                            style={{ margin: "16px 0px" }}
                          >
                            Saved Cards
                          </div>
                        )}
                        {cardDetails.map((card: any, index: number) => (
                          <div
                            onClick={() => {
                              setCardToken(card._id);
                            }}
                            style={{
                              border:
                                cardToken === card._id ? "1px solid black" : "",
                            }}
                            className={style["select-card"]}
                          >
                            <div
                              className={style["flex-center"]}
                              style={{ gap: "10px" }}
                            >
                              <div className={style["card-number"]}>
                                **** {card.cardDetails.number.slice(-4)}
                                {/* {card.cardDetails.number} */}
                              </div>
                              <div className={style["line"]}></div>
                              <div className={style["card-number"]}>
                                {card?.nameOnCard}
                              </div>
                            </div>
                            <div
                              className={style["flex-center"]}
                              style={{ gap: "10px" }}
                            >
                              <div className={style["card-number"]}>
                                {/* {card.cardDetails.cardType} */}
                              </div>
                              <img
                                src={handleCardType(card.cardDetails.cardType)}
                                alt="AmericanExpressIcon"
                                className={style["card-img"]}
                              />
                            </div>
                          </div>
                        ))}
                        {cardDetails.length > 0 && (
                          <div
                            className={style["add-card"]}
                            onClick={() =>
                              setIsModalOpen({
                                ...isModalOpen,
                                cardDetailsModal: true,
                              })
                            }
                          >
                            <AddPlusIcon />
                            <div>Add Card</div>
                          </div>
                        )}

                        {cardDetails.length === 0 && (
                          <div className={style["card-form-container"]}>
                            <div className={style["label-name"]}>
                              Credit / Debit Card Details
                            </div>
                            <div style={{ marginBottom: "16px" }}>
                              <div className={style["first-name-input"]}>
                                <Input
                                  placeholder="Full Name on the Card"
                                  value={cardHolderName}
                                  onChange={(e) =>
                                    setCardHolderName(
                                      e.target.value.trimStart()
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div style={{ marginBottom: "16px" }}>
                              <div className={style["first-name-input"]}>
                                <Input
                                  value={cardNumber}
                                  onChange={handleCardNumberChange}
                                  placeholder="Card Number"
                                  maxLength={19}
                                />
                              </div>
                            </div>
                            <div className={style["expiration-date"]}>
                              <div style={{ width: "80%" }}>
                                <div className={style["cvv-input"]}>
                                  <Input
                                    onChange={handleExpiryDateChange}
                                    placeholder="MM/YY"
                                    value={expiryDate}
                                    maxLength={5}
                                  />
                                </div>
                              </div>
                              <div style={{ width: "20%" }}>
                                <div className={style["cvv-input"]}>
                                  <Input
                                    value={securityCode}
                                    onChange={(e) => {
                                      setSecurityCode(e.target.value);
                                    }}
                                    placeholder="CVV"
                                    style={{ textAlign: "center" }}
                                    maxLength={3}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className={style["save-card"]}>
                        <Checkbox />
                        <div>Save card details with Razer pay gateway</div>
                      </div> */}
                          </div>
                        )}
                      </>
                    )}
                </div>
                <Button
                  loading={buttonLoading}
                  style={{
                    background: checkoutSetting?.customizeCTA?.backgroundColor,
                    color: checkoutSetting?.customizeCTA?.color,
                    fontStyle: checkoutSetting?.customizeCTA?.fontStyle,
                    fontWeight: checkoutSetting?.customizeCTA?.fontWeight,
                  }}
                  onClick={() => {
                    routing.gateway === "RAZORPAY" && CreateOrderRazorPay();
                    routing.gateway === "CYBERSOURCE" && getCyberSourcedata();
                    (routing.gateway === "STRIPE" ||
                      routing.gateway === "PAYPAL") &&
                      callStripe();
                    //  routing.gateway==='PAYPAL' && setisPaypal(true)
                  }}
                  className={style["pay-btn"]}
                >
                  <div className={style["flex-center"]} style={{ gap: "10px" }}>
                    <div className={style["pay-btn-text"]}>
                      {getCurrencySymbol(subsDetailsData.currency)}
                      {formatNumber(+subsDetailsData.convertedPrice + tax)}
                    </div>
                    {/* <div className={style['strick-price']}>₹1100</div> */}
                  </div>
                  <div className={style["flex-center"]} style={{ gap: "10px" }}>
                    <div className={style["pay-btn-text"]}>
                      {checkoutSetting?.customizeCTA?.text || "Pay"}
                    </div>
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.705 0.283993C10.6114 0.191806 10.5006 0.118949 10.3789 0.0695846C10.2571 0.0202205 10.1269 -0.00468376 9.99554 -0.00370909C9.86419 -0.00273443 9.73432 0.0241029 9.61335 0.0752685C9.49237 0.126434 9.38265 0.200927 9.29047 0.294493C9.19828 0.38806 9.12542 0.498868 9.07606 0.620591C9.02669 0.742314 9.00179 0.87257 9.00276 1.00392C9.00374 1.13527 9.03058 1.26514 9.08174 1.38611C9.13291 1.50709 9.2074 1.6168 9.30097 1.70899L14.671 6.99899L1.00097 6.99899C0.735751 6.99899 0.481397 7.10435 0.293861 7.29189C0.106324 7.47942 0.000968212 7.73378 0.000968235 7.99899C0.000968258 8.26421 0.106324 8.51856 0.293861 8.7061C0.481397 8.89364 0.735751 8.99899 1.00097 8.99899L14.664 8.99899L9.29997 14.286C9.11602 14.4732 9.01305 14.7252 9.01327 14.9877C9.0135 15.2502 9.11691 15.502 9.30118 15.6889C9.48546 15.8758 9.73583 15.9828 9.99826 15.9867C10.2607 15.9907 10.5142 15.8913 10.704 15.71L17.628 8.88799C17.7459 8.7717 17.8395 8.63314 17.9034 8.48035C17.9673 8.32757 18.0002 8.16361 18.0002 7.99799C18.0002 7.83238 17.9673 7.66842 17.9034 7.51563C17.8395 7.36285 17.7459 7.22428 17.628 7.10799L10.704 0.284993L10.705 0.283993Z"
                        fill={checkoutSetting?.customizeCTA?.color || "white"}
                      />
                    </svg>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer
          termsAndConditions={redirects.tnc}
          privacyAndPolicy={redirects.privPolicy}
        />
      </div>
      {cyberSourcedata?.accessToken?.length > 10 && (
        <CyberSource
          cyberSourcedata={cyberSourcedata}
          setCyberSourceSessionId={setCyberSourceSessionId}
        />
      )}
    </>
  );
};

export default Checkout;
